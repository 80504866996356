import React from 'react';

import Grid from '@mui/material/Grid';
import { StaticImage } from "gatsby-plugin-image"

const Location = () => {
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
          <Grid item>
            <StaticImage
              alt="service area"
              src="../images/service_area.png"
              width="380" height="380"
              style={{ borderRadius: '12px' }}
            />
          </Grid>
        </Grid>
    );
}

export default Location;
