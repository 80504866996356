import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const PageRow = ({ name, text, children }) => (
  <Grid
     container
     direction="row"
     sx={{
         marginTop: { xs: '24px', md: '24px' }
     }}
  >
    <Grid item xs={12}>
      <Typography variant="h5">{name}</Typography>
    </Grid>
    <Grid container rowSpacing={{ xs: 4, md: 0}}>
        <Grid
            item
            xs={12}
            md={5}
            sx={{
                marginTop: { xs: '12px', md: '12px' }
            }}
        >
          {text}
        </Grid>
        <Grid
            item
            xs={12}
            md={7} sx={{
                marginTop: { xs: '0', md: '12px' }
            }}
        >
          {children}
        </Grid>
      </Grid>
  </Grid>
)

export default PageRow;
