import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { GatsbyImage } from "gatsby-plugin-image"

const Hero = ({ image, width, heading, subheading }) => {
    return (
        <Box sx={{ textAlign: 'center' }}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                rowSpacing={1}
            >
              <Grid item sx={12}>
                  <Box
                      sx={{
                          marginTop: '20px',
                          marginBottom: '15px',
                          height: `${width}px`,
                          width: `${width}px`,
                      }}
                  >
                      <GatsbyImage
                          width={width}
                          image={image.gatsbyImageData}
                      />
                  </Box>
              </Grid>
              <Grid item xs={12}>
                  <Typography
                      variant="h5"
                      sx={{
                          fontSize: { xs: "1.2rem", md: "1.8rem" },
                          fontWeight: { xs: "700", md: "500" }
                      }}
                  >
                      {heading}
                  </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Typography variant="subtitle1">{subheading}</Typography>
              </Grid>
          </Grid>
        </Box>
    );
}

export default Hero;
