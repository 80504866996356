import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Tile = ({ name, text }) => {
    return (
        <Box
          sx={{
              margin: '0 auto',
              textAlign: 'center',
              display: 'inline-block',
          }}
        >
            <i
                data-eva={name}
                data-eva-height="48"
                data-eva-width="48"
            />
            <Typography
                variant="h6"
            >
                {text}
            </Typography>
        </Box>
    );
}

const Frequency = () => {
    return (
        <Grid
            container
            directon="row"
            columnSpacing={4}
            justifyContent={{ md: "center"}}
            alignItems={{ md: "center" }}
        >
          <Grid item>
              <Tile name="calendar-outline" text="One-Time" />
          </Grid>
          <Grid item>
              <Tile name="repeat-outline" text="Repeat" />
          </Grid>
        </Grid>
    )
}

export default Frequency;
