import React from 'react';

import { graphql } from 'gatsby'

import Grid from '@mui/material/Grid';

import Frequency from '../components/frequency';
import Hero from '../components/hero';
import Layout from "../components/layout"
import Location from '../components/location';
import PageRow from '../components/page-row';
import Seo from "../components/seo"
import Service from '../components/service';

const IndexPage = ({ data }) => (
  <Layout>
    <Seo />
    <Grid
        container
        direction="column"
        rowSpacing={4}
        sx={{
            justifyContent: 'center',
        }}
    >
      <Grid item xs={12}>
          <Hero
            image={data.datoCmsHero.image}
            width={data.datoCmsHero.width}
            heading={data.datoCmsHero.heading}
            subheading={data.datoCmsHero.subheading}
          />
      </Grid>
      <Grid item xs={12}>
        <PageRow
            name="Services"
            text={data.datoCmsDescription.services}
        >
          <Service
            data={data.allDatoCmsService.edges}
          />
        </PageRow>
      </Grid>
      <PageRow
        name="Frequency"
        text={data.datoCmsDescription.frequency}
      >
        <Frequency />
      </PageRow>
      <PageRow
        name="Location"
        text={data.datoCmsDescription.location}
      >
        <Location />
      </PageRow>
    </Grid>
  </Layout>
)

export const query = graphql`
query IndexPageQuery {
  datoCmsHero {
    image {
      gatsbyImageData(
        placeholder: BLURRED
        forceBlurhash: false
      )
    }
    width
    heading
    subheading
  }

  allDatoCmsService {
    edges {
      node {
        id
        name
      }
    }
  }

  datoCmsDescription {
    services
    frequency
    scheduling
    location
  }
}
`

export const Head = () => (<Seo />);
export default IndexPage
