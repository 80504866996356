import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Service = ({ data }) => {
    return (
      <Grid
        container
        spacing={2}
      >
        {data.map(edge => (
          <Grid item xs={6} md={4} key={edge.node.id}>
            <Typography variant="body" sx={{ fontWeight: '700' }}>
                {edge.node.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
}

export default Service;
